<template>
  <div>
      <h6>Bestellingen en eierdozen</h6>
      <a class="mb-2 text-decoration-none" @click="addQuestionToArray(this.editingGoal.questions)">+ Voeg bestelling toe</a>
      <br><br>
      <div class="input-group smoothContainer" v-for="(question, questionIndex) in this.editingGoal.questions" :key="questionIndex">
          <div class="form-floating mt-2 mb-2"> 
              <textarea v-model="question.question" id="goalInput" @input="cleanInputs()" class="form-control shared-textarea-small" placeholder=""></textarea>
              <label for="goalInput">Bestelling</label>
          </div>
          <div class="form-floating mt-2 mb-2"> 
              <textarea v-model="question.tools" id="goalInput" @input="cleanInputs()" aria-label="question" class="form-control shared-textarea-small" placeholder=""></textarea>
              <label for="goalInput">Beschikbare dozen</label>
          </div>
          <div class="input-group mb-3 text-decoration-none">
              <a href="#" class="tinyRemove text-danger text-decoration-none" @click="removeQuestionFromArray(this.editingGoal.questions,questionIndex)">Verwijderen <fa icon="fa-solid fa-remove" /></a> 
          </div>
      </div>
  </div>
  
  </template>
  
  <script>
  export default {
    props: {
      editingGoal: Object,
      questionTemplate: Object,
      levelIndex: Number
    },
    methods: {
      addQuestionToArray(questions){
        console.log("Adding new question")
        const newQuestion = { ...this.questionTemplate };
        questions.push(
          newQuestion
        );
      },
      removeQuestionFromArray(questions, questionIndex){
          questions.splice(questionIndex, 1);
      },
      cleanInputs()
      {
        this.editingGoal.questions.forEach(question => {
          question.question = this.forceToArrayStringMath(question.question);
          question.tools = this.forceToArrayStringMath(question.tools);
        });
      },
      forceToArrayStringMath(stringInput){
        //Turn tabs and spaces into commas
        let cleaned = stringInput.replace(/[\t]+/g, ",");

        //Allow multiply wildcard char
        cleaned= cleaned.replace(/[*]/g, "x");

        //Consistent divide character (forward and backward slash becomes : )
        cleaned= cleaned.replace(/[/\\]/g, ":");

        //Clean special characters except numbers, dots and commas and operators
        cleaned = cleaned.replace(/[^.,\dx+-:%?=\n]/g, "");
        return cleaned;
      }, 
    }
  }
  </script>