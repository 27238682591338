<template>
  <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">Apparaat koppelen</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
            <div class="form-floating mb-2 text-center">
              <div class="text-center">
                <img src="@/assets/images/quest2-inside.png" style="width:200px;" class="img-fluid" />
              </div>
              <br>
              <h5 class="text-center">Code zichtbaar in de Yuha VR app:</h5>
              <br>
              <div class="input-group input-group-lg mb-3">
                <input type="text" maxlength="4" class="form-control text-center" @input="tokenUpperCase" style="font-weight:bold; font-size:30px;" placeholder="XXXX" v-model="deviceToken" id="inputDeviceToken">
              </div>
           </div>
        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-default" @click="sessionSettings = false" data-bs-dismiss="modal">Annuleren</button>
          <button type="button" :class="[(deviceToken.length > 3) ? 'btn-primary' : 'btn-default' ]" class="btn" @click="addDeviceLogin" data-bs-dismiss="modal" >Koppelen</button>
        </div>
      </div>
    </div>
  </div>


  <h1>VR headsets</h1>
  
  <div class="nav justify-content-end mb-md-3">
    <button class="btn btn-primary me-md-2" type="button" data-bs-toggle="modal" data-bs-target="#staticBackdrop"><fa icon="fa-solid fa-vr-cardboard" /> Apparaat koppelen</button>
  </div>

  <div v-if="devices.length < 1" class="animateIn text-center smoothContainer card mb-3 pt-3" >
    Nog geen VR headsets gekoppeld.<br><br>
    Druk hier boven op 'Apparaat koppelen'.<br>
    Voer vervolgens  de code in die zichtbaar is in de Yuha VR app.
  </div>

  <div class="animateIn smoothContainer card mb-3" 
    v-for="(device, index) in devices"
    :key=index
    >
    
    <div class="card-body">
      <h2><img src="@/assets/images/quest2.png" class="img float-end" /> {{ device.name }} <a :id="device.id" :href="'#' + device.id" @click="device.edit = !device.edit"><fa icon="fa-solid fa-edit" /></a></h2>  
      <h5 v-if="!device.edit && device.selectedGroup">Groep: {{ device.selectedGroup.name }}</h5>
      <div class="input-group mb-3" v-if="device.edit">
        <br>
        <span class="input-group-text" id="deviceNameAddon">Naam:</span>
        <input class="form-control" type="text" v-model="device.name" id="deviceName" placeholder="Device name" required>
      </div>

      <div class="input-group mb-3" v-if="device.edit">
        <span class="input-group-text" id="groupAddon">Groep:</span>
        <select v-model="device.selectedGroup" class="form-select" id="groupSessionSelection">
          <option v-for="(group, groupIndex) in studentGroups" :key="groupIndex" :value="group" :selected="device.groupId === group.id">{{ group.name }}</option>
        </select>
      </div>

      <div class="mb-3" v-if="device.edit">
        <label for="html5colorpicker" class="form-label">Kleurcode:</label>
        <input type="color" class="form-control form-control-color" id="html5colorpicker" v-model="device.colorCode" style="width:40px;">
      </div>

      <div class="input-group mb-3" v-if="device.edit">
        <button class="btnbtn3d btn-primary" type="button" id="button-addon2" @click="saveDeviceSettings(device)">Opslaan</button>
      </div>


      <div class="mt-md-3 nav justify-content-end" v-if="device.edit">
        <button class="btn btn-danger me-md-2 " type="button" @click="removeDeviceLogin(device)"><fa icon="fa-solid fa-ban" /> Apparaat uitloggen</button>
      </div>

      <hr v-if="device.edit" class="mb-3">

      <div class="row mt-5">
        <div class="col-lg-6 col-sm-12">
          <div class="input-group mb-3">
            <input type="text" v-model="device.stagedchat" class="form-control" placeholder="Verstuur een bericht.." aria-label="Recipient's username" aria-describedby="button-addon2">
            <button class="btn btn-primary" type="button" id="button-addon2" @click="sendMessageToDevice(device)"><fa icon="fa-solid fa-paper-plane" /> Verstuur</button>
          </div>
         
        </div>
        <div class="col-lg-6 col-sm-12">
          <p v-if="device.chat != null && device.chat != ''">
            <b>Laatste bericht:</b> <i>{{ device.chat }}</i>
          </p>
        </div>
      </div>
    </div>
  </div>

  <HelpComponent message='De volgende VR brillen zijn ingelogd en gekoppeld aan jouw account.<br>
      Heb je een nieuw VR apparaat? Open dan de Yuha app op het apparaat om te zien wat de koppel code is.<br>
      Klik hier onder vervolgens op "Apparaat koppelen" en voer de code in.'/>

  <div class="smoothContainer admin" v-if="this.$store.state.user.admin">
    <h5>Admin device options</h5>
    <div class="nav justify-content-end mb-md-3">
      <button v-if="this.$store.state.user.admin" class="btn btn-primary me-md-2" type="button" @click="addDummyDevice"><fa icon="fa-solid fa-vr-cardboard" /> Dummy apparaat toevoegen</button>
    </div>
  </div>
</template>

<script>
import { auth, db } from '@/main'
import { firestore } from '@/main'
import HelpComponent from './Nested/HelpComponent.vue';

export default {
  name: "DevicesModule",
  data() {
    return {
      help: false,
      devices: [],
      studentGroups: [],
      deviceToken:"",
      selectedGroup: 0,
      newChatMessage: "",
    };
  },
  components:{
    HelpComponent
  },
  methods: {
    tokenUpperCase(){
      this.deviceToken = this.deviceToken.toUpperCase();
    },
    saveDeviceSettings(device)
    {
      device.groupId = "";
      device.groupName = "";
      if(device.selectedGroup){
        device.groupId = device.selectedGroup.id;
        device.groupName = device.selectedGroup.name;
      }
      device.edit = false;
      db.collection("/devices").doc(device.id).update({
          name: device.name,
          groupId: device.groupId,
          groupName: device.groupName
      });
    },
    sendMessageToDevice(device)
    {
      device.chat = device.stagedchat;
      device.stagedchat = "";
      db.collection("/devices").doc(device.id).update({
          chat: device.chat
      }).then(() => {
          console.log("Chat was set.");
      }).catch((error) => {
          console.error("Could not set chat: ", error);
      });
    },
    onLoadedGroups(items) {
      let _studentGroups = [];
      items.forEach((item) => {
        let id = item.id;
        let data = item.data();
        _studentGroups.push({
          id: id,
          name: data.name
        });
      });
      this.studentGroups = _studentGroups;

      this.mapSelectedGroups();
    },
    mapSelectedGroups(){
      this.devices.forEach((device) => {
        console.log(device.groupId +" == ");
        this.studentGroups.forEach((group) => {
          if(device.groupId === group.id)
          {
            console.log(device.name + " belongs to group " + group.name);
            device.selectedGroup = group;
          }
        });
      });
    },
    addDummyDevice(){
      if(this.$store.state.user.admin == true){
        let timestamp = firestore.FieldValue.serverTimestamp();

        let randomString = Math.random().toString(36).slice(-4);
        randomString = randomString.toUpperCase();
        
        let dummyDevice = {
          selectedGroup:"",
          added: timestamp,
          name: "DummyDevice_" + timestamp,
          loggedIn: timestamp,
          schoolId:"",
          token: randomString
        }

        db.collection('devices').doc().set(dummyDevice).then(()=>
        {
            alert("CODE: " + randomString);
        });
      }
    },
    addDeviceLogin()
    {
      if(this.deviceToken.length < 4){
          const input = document.getElementById('inputDeviceToken');
          input.focus();
          input.select();
      }
      else{
        let timestamp = firestore.FieldValue.serverTimestamp();
        db.collection("devices").where('token', '==', this.deviceToken).get()
          .then(querySnapshot => {
            querySnapshot.forEach(doc => {
              // Clear token and connect
              db.collection('devices').doc(doc.id).update({
                schoolId: this.$store.state.school,
                addedById: auth.currentUser.uid,
                loggedIn: timestamp,
                token: ""
              })
            });
          });

        console.log("Connected new device " + this.deviceToken); 
      }
    },
    removeDeviceLogin(device)
    {
       if(confirm("Weet je zeker dat je " + device.name + " wilt uitloggen?\nJe moet vervolgens opnieuw inloggen op het apparaat.")){
        console.log("Logging out device");  

        let batch  = db.batch();
        batch.delete(db.collection("/devices").doc(device.id));
        batch.delete(db.collection("/schools/" + this.$store.state.school + "/devices").doc(device.id));
        batch.commit().then(() => {
            console.log("Document successfully deleted!");
        }).catch((error) => {
            console.error("Error removing document: ", error);
        });
       }
    },
    onDataChange(items) {
      let _devices = [];
      items.forEach((item) => {
        let data = item.data();

        //Logged in datetime
        let date = data.added.toDate();

        let dateString = date.toLocaleDateString("nl-NL", {
          day: "numeric",
          month: "long",
          year: "numeric"
        });

        // Format the time as "uur:minuten" (e.g. "15:30")
        let timeString = date.toLocaleTimeString("nl-NL", {
          hour: "numeric",
          minute: "numeric"
        });

        _devices.push({
          id: item.id,

          name: data.name,
          deviceId: data.deviceId,
          sessionId: data.sessionId,
          groupId: data.groupId,
          chat: data.chat,
          type: data.type,
          added: (dateString + " " + timeString),
        });
      });
      this.devices = _devices;

      this.mapSelectedGroups();
    },
  },
  mounted() {
    this.unsubscribe = db.collection("/devices").where('schoolId', '==', this.$store.state.school).onSnapshot(this.onDataChange);
    db.collection("/schools/" + this.$store.state.school + "/groups").orderBy("name", "desc").get().then(this.onLoadedGroups);
  },
  beforeUnmount() {
    this.unsubscribe();
  }
};
</script>