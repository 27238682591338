<template>
    <div v-if="loading" class="d-flex justify-content-center" style="margin: 20px;">
      <div class="spinner-grow text-info" role="status">
        <span class="visually-hidden">Laden...</span>
      </div>
    </div>    
</template>

<script>
export default {
  props: {
    loading: Boolean
  }
}
</script>
    