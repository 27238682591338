<template>
   <div class="container" style="max-width:300px;">
   <form v-if="!resettingPassword" class="form-signin" @submit.prevent="signIn">
      <div class="img-fluid logo">
      <img src="@/assets/images/Yuha_TextLogo_Black.svg" class="img-fluid" />
      </div>
      <h5>Portaal</h5>
      <br>
      <label for="inputEmail" class="sr-only">Email address</label>
      <input type="email" id="inputEmail" class="form-control" placeholder="Email adres" v-model="email" required autofocus autocomplete="on">
      <br>
      <label for="inputPassword" class="sr-only">Wachtwoord</label>
      <input type="password" id="inputPassword" class="form-control" placeholder="Wachtwoord" v-model="password" required autocomplete="on">
      <br>
      <div class="checkbox mb-3">
        <label>
          <input type="checkbox" value="remember-me" v-model="rememberLogin"> Blijf ingelogd
        </label>
      </div>
      <button v-if="!loggingIn" class="btn btn3d btn-lg btn-primary btn-block" type="submit">Inloggen</button>
      <LoadingSpinner :loading="loggingIn" />
      
      <p v-if="errMsg" class="mt-3">{{ errMsg }}</p>

      <p class="mt-5"><a href="#" @click="resettingPassword = true">Wachtwoord wijzigen</a></p>
    </form>

    <form v-if="resettingPassword" class="form-signin" @submit.prevent="resetPass()">
      <div class="img-fluid logo">
      <img src="@/assets/images/Yuha_TextLogo_Black.svg" class="img-fluid" />
      </div>

      <p v-if="!emailSending && !sentReset">Via een e-mail kun je een nieuw wachtwoord kiezen.</p>
      <p class="mt-3" v-if="!emailSending && !sentReset">Wat is je e-mail adres?</p>

      <p v-if="sentReset" class="mt-3">Een wachtwoord reset e-mail is verstuurd. Controleer je mailbox.</p>
      <p v-if="sentReset" class="mt-3"><i>Geen mail ontvangen? Controleer ook je spam map.</i></p>

      <p v-if="sentReset" class="mt-3 justify-content-center"><a href="/">Terug naar inloggen</a></p>

      <label v-if="!emailSending && !sentReset" for="inputEmailReset" class="sr-only">Email address</label>
      <input v-if="!emailSending && !sentReset" type="email" id="inputEmailReset" class="form-control" placeholder="Email adres" v-model="email" required autofocus autocomplete="off">
      
      <button v-if="!emailSending && !sentReset" class="mt-3 btn btn3d btn-lg btn-primary btn-block" type="submit">Verstuur</button>
  
      <LoadingSpinner :loading="emailSending" />

      <p v-if="errMsg" class="mt-3">{{ errMsg }}</p>

      <p class="mt-3" v-if="!emailSending && !sentReset"><a href="#" @click="resettingPassword = false">Terug naar login</a></p>
    </form>

  </div>
</template>

<script>
  import { auth,firebase } from '@/main'
  import LoadingSpinner from './Nested/LoadingSpinner.vue';

  export default {
    name: 'LoginModule',
    data() {
        return {
            resettingPassword: false,
            emailSending: false,
            sentReset: false,
            loggingIn: false,
            rememberLogin: true,
            email: "",
            password: "",
            errMsg: null,
        };
    },
    methods: {
        resetPass() {
            if (!this.email) {
                this.error = "Please type in a valid email address.";
                return;
            }
            this.errMsg = null;
            this.emailSending = true;
            auth.sendPasswordResetEmail(this.email)
                .then(() => {
                this.emailSending = false;
                this.sentReset = true;
            })
                .catch(error => {
                this.emailSending = false;
                this.errMsg = error.message;
            });
        },
        async signIn() {
          this.loggingIn = true;

          try {
            if (this.rememberLogin) {
              console.log('Persisting login');
              await auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);
            } 
            else {
              console.log('Session login');
              await auth.setPersistence(firebase.auth.Auth.Persistence.SESSION);
            }

            await auth.signInWithEmailAndPassword(this.email, this.password);
            console.log('Successfully logged in!');
            this.$store.state.freshLogin = true;
            
            //The login state change will catch the redirect to the dashboard
          } 
          catch (error) {
            this.loggingIn = false;
            switch (error.code) {
              case 'auth/invalid-email':
                this.errMsg = 'Ongeldige E-mail.';
                break;
              case 'auth/user-not-found':
                this.errMsg = 'Geen account gevonden met dit E-mail adres';
                break;
              case 'auth/wrong-password':
                this.errMsg = 'Wachtwoord onjuist.';
                break;
              default:
                this.errMsg = 'E-mail of wachtwoord is incorrect.';
                break;
            }
          }
        }
    },
    components: { LoadingSpinner }
}
</script>

<style>
.container{
  text-align: center;
}
.logo{
  padding:30px;
}
</style>