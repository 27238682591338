import { createRouter, createWebHistory } from 'vue-router'

//Components
import Home from './components/Home.vue'
import Login from './components/Login.vue'
import Invitation from './components/Invitation.vue'
import LoginDevice from './components/LoginDevice.vue'
import Activities from './components/Activities.vue'
import Activity from './components/Activity.vue'
import Students from './components/Students.vue'
import Teachers from './components/Teachers.vue'
import Student from './components/Student.vue'
import Devices from './components/Devices.vue'
import Schools from './components/Schools.vue'
import Games from './components/Games.vue'
import Settings from './components/Settings.vue'
import Dashboard from './components/Dashboard.vue'

//Route mapping
const routes = [
    { path: '/', component: Home },
    { path: '/login', component: Login },
    { path: '/invitation', component: Invitation },
    { path: '/logindevice/:deviceId', component: LoginDevice },
    { path: '/activities/:hideMenu', component: Activities },
    { path: '/activity/:studentId/:studentName/:activityId', component: Activity },
    { path: '/students', component: Students },
    { path: '/teachers', component: Teachers },
    { path: '/student/:studentId', component: Student },
    { path: '/devices', component: Devices },
    { path: '/schools', component: Schools },
    { path: '/games', component: Games },
    { path: '/settings', component: Settings },
    { path: '/dashboard', component: Dashboard },
    { 
      path: '/logout',
    },

  ]

export const router = createRouter({
  history: createWebHistory(),
  routes: routes,
  beforeEnter: (to, from, next) => {
    if (location.protocol !== 'https:') {
      location.href = 'https:' + window.location.href.substring(window.location.protocol.length);
    } else {
      next();
    }
  }
})