<template>
  <div class="lineContent">
    <div class="line" @click="this.opened = !this.opened">
      <div class="row">
        <div class="col-md-8">
          <h5>{{ name }}</h5>
          <p class="lead">{{ templates.length }} {{ (templates.length > 1) ? "Leerdoelen" : "Leerdoel" }}</p>
        </div>
        <div class="col-md-3 d-flex align-items-center">
          <div class="progress" style="width: 100%">
            <div class="main-progress progress-bar" v-if="lineHasBeenPlayed()"
              role="progressbar" 
              :style="'width: ' + getProgressLine() + '%'" :aria-valuenow="getProgressLine()"
              aria-valuemin="0"
              aria-valuemax="100">
            </div>
            <div class="main-progress progress-bar progress-bar-striped progress-bar-animated" v-if="loadingResults()"
              role="progressbar" 
              :style="'width: 100%; background-color:#cdcdcd'" 
              :aria-valuenow="100"
              aria-valuemin="0"
              aria-valuemax="100">
            </div>
            <div class="sub-progress progress-bar bg-info" v-if="lineHasBeenPlayed() && getLineProgressWhereBusy() > 0"   
              role="progressbar" 
              :style="'width: ' + getLineProgressWhereBusy() + '%'" :aria-valuenow="getLineProgressWhereBusy()"
              aria-valuemin="0" 
              aria-valuemax="100">
            </div>
            <div class="sub-progress progress-bar bg-warning" v-if="lineHasBeenPlayed() && getLineProgressWhereProblems() > 0"   
              role="progressbar" 
              :style="'width: ' + getLineProgressWhereProblems() + '%'" :aria-valuenow="getLineProgressWhereProblems()"
              aria-valuemin="0" 
              aria-valuemax="100">
            </div>
          </div>
        </div>
        <div class="col-md-1 chevron d-flex align-items-center">
          <fa v-if="this.opened" icon="fa-solid fa-chevron-up" />
          <fa v-else icon="fa-solid fa-chevron-down" />
        </div>
      </div>
    </div>

    <div v-if="this.opened" class="lineContentPane">
      <div class="animateDown" v-for="template in templates" :key="template.id" @click="openTemplateResults(template)">
        <div class="template row" :id="template.id">
          <div class="col-md-8">
            <h6>{{ template.name }}</h6>
          </div>
          <div class="col-md-3 d-flex align-items-center">
            <div class="progress" style="width: 100%">
              <div class="progress-bar" role="progressbar" :style="'width: ' + getProgressTemplate(template) + '%'" :aria-valuenow="getProgressTemplate(template)" aria-valuemin="0"
                aria-valuemax="100"></div>
                <div v-if="getProgressTemplateWhereBusy(template) > 0" class="progress-bar bg-info" role="progressbar" 
                  :style="'width: ' + getProgressTemplateWhereBusy(template) + '%'" 
                  :aria-valuenow="getProgressTemplateWhereBusy(template)"
                  aria-valuemin="0" aria-valuemax="100">
                </div>
                <div v-if="!template.completed && getProgressTemplateWhereProblems(template) > 0" class="progress-bar bg-warning" role="progressbar" 
                  :style="'width: ' + getProgressTemplateWhereProblems(template) + '%'" 
                  :aria-valuenow="getProgressTemplateWhereProblems(template)"
                  aria-valuemin="0" aria-valuemax="100">
                </div>
            </div>
          </div>
          <div class="col-md-1 star d-flex align-items-center">
            <fa v-if="template.completed" icon="fa-solid fa-star" />
            <div v-else style="color:rgb(176, 176, 176)"><fa icon="fa-solid fa-star" /></div>
          </div>
        </div>

        <div v-if="templateExpandedResults && template == templateExpandedResults" class="templateResultsPane">
          <div class="result animateDown" v-if="!templateExpandedResults?.lastActivity?.results || templateExpandedResults.lastActivity.results.length < 1">
            Geen resultaten gevonden in laatste activiteit.
          </div>
          <div v-else>
            <div class="result animateDown" v-show="levelResult.logs.length>0" v-for="levelResult in templateExpandedResults.lastActivity.results" :key="levelResult.id">
              <div class="row">
                <div class="col-md-4">
                  <h6>Level {{ levelResult.level + 1}}</h6>
                </div>
                <div class="col-md-8">
                  <p v-for="(log, logIndex) in levelResult.logs" :key="logIndex" class="loggedItem" :class="{'good': log.positive, 'bad': !log.positive}">
                    <fa v-if="!log.positive" icon="fa-solid fa-xmark" />
                    <fa v-if="log.positive" icon="fa-solid fa-check" />
                    {{ log.log }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
export default {
  props: {
    name: String,
    templates: Array,
  },
  data() {
    return {
      orangeThreshold: 60, //Percentage of right answers to complete a level. Less than this? Color bar orange.
      opened: false,
      templateExpandedResults: null
    };
  },
  methods: {
    openTemplateResults(template)
    {
      if(template != this.templateExpandedResults)
      {
        this.templateExpandedResults = template;
        console.log("Open template " + template.id)
      }
      else{
        this.templateExpandedResults = null;
      }
    },
    loadResults() {
      console.log("Results");
    },
    getProgressLine()
    {
      let totalTemplates = this.templates.length;
      let templatesCompleted = this.templates.filter(item => item.completed).length;
      console.log(this.name + " progress line: " + templatesCompleted + " / " + totalTemplates);
      return (templatesCompleted/totalTemplates) * 100;
    },
    getLineProgressWhereBusy()
    {
      let templatesWhereBusy = this.templates.filter(template => {
        return !template.completed && this.getProgressLastLevel(template) >= 0 && this.getProgressLastLevel(template) >= this.orangeThreshold;
      });
      let percentage = (templatesWhereBusy.length / this.templates.length) * 100;
      return percentage;
    },
    getLineProgressWhereProblems()
    {
      let templatesWhereProblems = this.templates.filter(template => {
        return !template.completed && this.getProgressLastLevel(template) >= 0 && this.getProgressLastLevel(template) < this.orangeThreshold;
      });
      let percentage = (templatesWhereProblems.length / this.templates.length) * 100;

      return percentage;
    },
    lineHasBeenPlayed()
    {
        for (let index = 0; index < this.templates.length; index++) {
          const template = this.templates[index];
          const templateHasBeenPlayed = this.templateHasBeenPlayed(template);
          if(templateHasBeenPlayed) return true;
        }
        return false;
    },
    loadingResults()
    {
      for (let index = 0; index < this.templates.length; index++) {
          const template = this.templates[index];
          if(template.loadingResults) return true;
        }
        return false;
    },
    templateHasBeenPlayed(template)
    {
      return (template.completed || template.level > 0 || (template?.lastActivity?.results && template.lastActivity.results.length > 0))
    },
    getProgressTemplate(template)
    {
      if(template.completed) return 100;
      if(!this.templateHasBeenPlayed(template)) return 0;
      
      let templateProgress = template.level / template.maxLevels;

      return templateProgress * 100;
    }, 
    getProgressTemplateWhereBusy(template)
    {
        if (template.completed || !this.templateHasBeenPlayed(template)) {
          return 0;
        }

        const busyWithTemplate = this.getProgressLastLevel(template) >= this.orangeThreshold;
        return busyWithTemplate ? (1 / template.maxLevels) * 100 : 0;
    }, 
    getProgressTemplateWhereProblems(template)
    {
      if (template.completed || !this.templateHasBeenPlayed(template)) {
          return 0;
        }

        const problemsWithTemplate = this.getProgressLastLevel(template) < this.orangeThreshold;
        return problemsWithTemplate ? (1 / template.maxLevels) * 100 : 0;
    }, 
    getProgressLastLevel(template)
    {
      if (!template?.lastActivity?.results || template.lastActivity.results.length < 1) {
        return -1;
      }
      
      let lastLevelResult = template.lastActivity.results[template.lastActivity.results.length-1];
      let rightAnswers = lastLevelResult.rightAnswers;
      let wrongAnswers = lastLevelResult.wrongAnswers;
      
      let totalAnswers = (rightAnswers+wrongAnswers);
      if(totalAnswers == 0) return 0;

      let percentageRight = Math.round((rightAnswers / totalAnswers) * 100);
      let percentageOfLevels = percentageRight / template.maxLevels;

      return percentageOfLevels;
    }
  }
}
</script>

<style>
.bg-info{
  background-color: rgb(43, 209, 255) !important;
}

.line .chevron {
  font-size: 2em;
  cursor: pointer;
}

.lineContent {
  margin-bottom: 20px;
  cursor: pointer;
}

.line {
  border: none;
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.template {
  border: none;
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin-left: 10px;
  margin-right: 20px;
  cursor: pointer;
}
.template h6{
  margin-top: 10px;
}
.template .star {
  font-size: 2em;
  color: orange;
}

.result {
  border: none;
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin-left: 40px;
  margin-right: 40px;
  margin-bottom: 5px;
}
</style>