<template>
  <h1 class="animateIn"><span class="back" @click="this.$router.back();"><fa icon="fa-solid fa-caret-left" /></span> Activiteit: {{ this.$route.params.studentName }}</h1><br>

  <div class="smoothContainer">
    <dl class="row" v-if="activity">
      <dt class="col-sm-2"><fa icon="fa-solid fa-question" style="color:cornflowerblue;"/> Status</dt>
      <dd class="col-sm-10" v-if="activity.state == 2"> Klaar</dd>
      <dd class="col-sm-10" v-if="activity.state == 1"> Bezig..</dd>
      <dt class="col-sm-2"><fa icon="fa-solid fa-hashtag" style="color:blue;"/> Levels</dt>
      <dd class="col-sm-10">{{ levelResults.length }} gespeeld</dd>
      <dt class="col-sm-2" v-show="totalMovementRounded > 0"><fa icon="fa-solid fa-person-running" style="color:blueviolet;"/> Beweging</dt>
      <dd class="col-sm-10" v-show="totalMovementRounded > 0" style="color:blueviolet; font-weight: bold;">
        <div class="red-tooltip" style="display: inline-block;" 
            ref="info" data-bs-toggle="tooltip" 
            data-bs-placement="top" 
            title="Reisafstand (meters) van hoofd en handen tijdens het spelen opgeteld">
            {{ totalMovementRounded }}
        </div>
      </dd>
    </dl>
  </div>
  <div v-if="levelResults.length < 1 && activity.state == 1" class="smoothContainer">
    Zodra de leerling een level heeft gehaald worden de resulaten getoond.
  </div>
  <div v-if="levelResults.length < 1 && activity.state == 2" class="smoothContainer">
    Wanneer een leerling een tijdje heeft gespeeld worden hier de details getoond.
  </div>

  <div class="smoothContainer" v-for="(result, levelResultIndex) in levelResults" :key="levelResultIndex" :id="result.id" :class="{ hashTarget: result.hashTarget }">
    <h6>{{ result.settingTemplateName }}</h6>
    <dl class="row m-1" >
      <dt class="col-sm-3">
        <p><fa v-if="!result.levelComplete" icon="fa-solid fa-xmark" style="color:grey;"/><fa v-if="result.levelComplete" icon="fa-solid fa-check" style="color:green;"/> Level: {{ result.level }}</p>
        <p><fa icon="fa-solid fa-trophy" style="color:gold;"/> Uitslag: {{ result.scoreText }}</p>
        <p><fa icon="fa-solid fa-person-running" style="color:blueviolet;"/> Beweging: {{ result.movementRounded }}</p>
        <img v-if="result.gameId && result.gameId!=''" :alt="result.gameId" :src="require(`@/assets/images/gamecards/${result.gameId}.png`)" style="max-width: 100px;"/>
      </dt>
      <dd class="col-sm-9" v-show="result.logs.length>0">
        <p v-for="(log, logIndex) in result.logs" :key="logIndex" class="loggedItem" :class="{'good': log.positive, 'bad': !log.positive}">
          <fa v-if="!log.positive" icon="fa-solid fa-xmark" />
          <fa v-if="log.positive" icon="fa-solid fa-check" />
          {{ log.log }}
        </p>
      </dd>
      <dd class="col-sm-9" v-show="result.logs.length==0">
        <p class="loggedItem">
          Geen antwoorden gegeven
        </p>
      </dd>
    </dl>
  </div>
</template>

<script>
import { db, firebase } from '@/main';
import { Tooltip } from 'bootstrap'

export default {
  name: 'SessionModule',
  data() {
    return {
      activity: {
        played: "...",
        name: "...",
      },
      settingTemplates: [],
      activities: [],
      levelResults: [],
      totalMovement: 0,
      totalMovementRounded: 0,
      showGoals : false
    };
  },
  mounted() {
    new Tooltip(this.$refs.info)
    this.loadActivity(this.$route.params.activityId); //Start with activity from route
  },
  beforeUnmount() {
    if(this.unsubscribe){
      this.unsubscribe();
    }
  },
  methods: {
    async loadActivity(activityId) {
      const doc = await db.collection("/activities").doc(activityId).get();
      this.activity = doc.data();
      this.activity.id = doc.id;

      await this.loadSettingsTemplates(this.activity.settingTemplateIds);
      this.loadResults(this.activity);
    },
    
    loadResults(activity){
      console.log("Get results for activity " + activity.id);
      this.unsubscribe = db.collection("/activities").doc(activity.id).collection("results").orderBy("logged").onSnapshot(this.onResultsChange);
    },
    onResultsChange(items) {
      console.log(items);

      //Grab hash from url and set let focus
      let focus = this.$route.hash.replace("#", "");

      //Reset total movement
      this.totalMovement = 0;
      this.totalMovementRounded = 0;

      let _levelResults = [];
      items.forEach((item) => {
        let data = item.data();
        data.id = item.id;
        data.settingTemplateName = this.settingTemplates.find(x => x.id === data.settingTemplateId)?.name || "";
        if(data.id == focus) data.hashTarget = true;

        data.level = 1+data.level; //Level is id (zero based index) plus 1

        let totalAnswers = (data.rightAnswers+data.wrongAnswers);
        let percentageRight = Math.round((data.rightAnswers / totalAnswers) * 100);

        let percentageText = "";
        if (!isNaN(percentageRight) && percentageRight !== null) {
          percentageText = " (" + (percentageRight) +"%)";
        }
        data.scoreText = data.rightAnswers + "/" + totalAnswers + percentageText;
        
        let _logs = [];
        let _seperateLogs;

        //Parse logs
        if(!data.log.includes("{")){
          //Legacy non JSON logs
          _seperateLogs = data.log.split(";");
          _seperateLogs.forEach((log) => {
            if(log.length>3){
              let _logParts = log.split(",");

              let correctBool = (_logParts[1] == "1");

              _logs.push({
                log: _logParts[0],
                positive: correctBool
              })
            }
          });
        }
        else
        {
           //JSON logs
            let json = JSON.parse(data.log);
            for (let i = 0; i < json.descriptions.length; i++) {
              const event = json.descriptions[i];
              const correctBool = json.correct[i];
              _logs.push({
                log: event,
                positive: correctBool
              })
            }
        }
        data.logs = _logs


        //Calculate total movement by combining level movements
        data.movementRounded = data.movement.toFixed(2);
        this.totalMovement += (data.movement) ? data.movement : 0;
        this.totalMovementRounded = this.totalMovement.toFixed(2);

        _levelResults.push(data);
      });

      this.levelResults = _levelResults.reverse();

      //Scroll to hash
      if(focus){
        this.$nextTick(() => {
          let element = document.getElementById(focus);
          if (element) {
            const elementRect = element.getBoundingClientRect();
            const viewportHeight = window.innerHeight || document.documentElement.clientHeight;
            const scrollPosition = window.pageYOffset || document.documentElement.scrollTop;

            if (elementRect.bottom > viewportHeight) {
              const scrollOffset = elementRect.bottom - viewportHeight + scrollPosition;
              window.scrollTo({ top: scrollOffset, behavior: "smooth" });
            }
          }
        });
      }
    },
    onActivitiesChange(items) {
      console.log(items);

      let _activities = [];
      items.forEach((item) => {
        let data = item.data();
        data.id = item.id;
       
        _activities.push(data);
      });

      this.activities = _activities;
    },
    loadActivities(){
      console.log("Get activities for " + this.$route.params.studentId);
      this.unsubscribe = db.collection("/activities")
      .where('studentId', '==', this.$route.params.studentId)
      .where('schoolId', '==', this.$store.state.school)
      .onSnapshot(this.onActivitiesChange);
    },
    async loadSettingsTemplates(templateIds) {
      let _settingTemplates = [];

      // Split the templateIds into chunks of 10
      const chunkPromises = [];
      for (let i = 0; i < templateIds.length; i += 10) {
        const chunkIds = templateIds.slice(i, i + 10);

        // Query the database for the current chunk of templateIds
        const promise = db.collection("/settingTemplates")
          .where(firebase.firestore.FieldPath.documentId(), "in", chunkIds)
          .get();

        chunkPromises.push(promise);
      }

      // Wait for all promises to resolve
      const chunkResults = await Promise.all(chunkPromises);

      // Add the retrieved settingTemplates to the array
      chunkResults.forEach((items) => {
        items.forEach((item) => {
          let data = item.data();
          data.id = item.id;
          _settingTemplates.push(data);
        });
      });

      this.settingTemplates = _settingTemplates;
    }
  }
}
</script>

<style scoped>
  .hashTarget{
    border: 3px solid #30b2d9;
  }
</style>