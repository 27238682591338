<template>
<div>
    <h6>Vragen en uitleg</h6>
    <a class="mb-2 text-decoration-none" @click="addQuestionToArray(this.editingGoal.questions)">+ Voeg vraag toe</a>
    <br><br>
    <div class="input-group smoothContainer" v-for="(question, questionIndex) in this.editingGoal.questions" :key="questionIndex">
        <div class="form-floating mt-2 mb-2"> 
            <textarea style="height: 100%;" rows="4" cols="50" v-model="question.question" id="goalInput" aria-label="question" class="form-control shared-textarea" placeholder="Bijvoorbeeld: 'De kat <loopt,loop,lopen> over straat'"></textarea>
            <label for="goalInput">Vraag</label>
        </div>
        <div class="form-floating mt-2 mb-2"> 
            <textarea style="height: 100%;" rows="4" cols="50" v-model="question.explanation" id="goalInput" aria-label="question" class="form-control shared-textarea" placeholder="Optionele uitleg grammatica"></textarea>
            <label for="goalInput">Uitleg</label>
        </div>
        <div class="input-group mb-3 text-decoration-none">
            <a href="#" class="tinyRemove text-danger text-decoration-none" @click="removeQuestionFromArray(this.editingGoal.questions,questionIndex)">Vraag verwijderen <fa icon="fa-solid fa-remove" /></a> 
        </div>
    </div>
</div>

</template>

<script>
export default {
  props: {
    editingGoal: Object,
    questionTemplate: Object,
    levelIndex: Number
  },
  methods: {
    addQuestionToArray(questions){
      console.log("Adding new question")
      const newQuestion = { ...this.questionTemplate };
      questions.push(
        newQuestion
      );
    },
    removeQuestionFromArray(questions, questionIndex){
        questions.splice(questionIndex, 1);
    }
  }
}
</script>