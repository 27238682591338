<template>
   <div class="container" style="max-width:300px;">

    <div v-if="$route.params.deviceId == 'added'">
      <div class="img-fluid logo">
        <img src="@/assets/images/Yuha_TextLogo_Black.svg" class="img-fluid" />
        </div>
        <h1 class="h3 mb-3 font-weight-normal">Apparaat gekoppeld!</h1>

        <p>
          U kunt nu deze pagina sluiten en terugkeren naar de app.
        </p>

        <p>
          Dit apparaat is nu ook beschikbaar in het portaal.
        </p>
    </div>

    <form class="form-signin" @submit.prevent="signInDevice" v-if="$route.params.deviceId != 'added'">
        <div class="img-fluid logo">
        <img src="@/assets/images/Yuha_TextLogo_Black.svg" class="img-fluid" />
        </div>
        <h1 class="h3 mb-3 font-weight-normal">Koppelen VR bril</h1>
        <br>
        <p>
          Log in om dit apparaat te koppelen:
        </p>
        <label for="inputEmail" class="sr-only">Email address</label>
        <input type="email" id="inputEmail" class="form-control" placeholder="Email adres" v-model="email" required autofocus autocomplete="on">
        <br>
        <label for="inputPassword" class="sr-only">Wachtwoord</label>
        <input type="password" id="inputPassword" class="form-control" placeholder="Wachtwoord" v-model="password" required autocomplete="on">
        <br>
  
        <button v-if="!adding" class="btn btn-lg btn-primary btn-block" type="submit">Koppelen</button>        
        <LoadingSpinner :loading="adding" />
        
        <p v-if="errMsg">{{ errMsg }}</p>
      </form>
  </div>
</template>


<script>
  import { auth, db, firestore } from '@/main'
  import { router } from '@/router'
  import LoadingSpinner from './Nested/LoadingSpinner.vue';

  export default {
    name: 'LoginDeviceModule',
    data() {
        return {
            adding: false,
            email: "",
            password: "",
            errMsg: null,
        };
    },
    methods: {
        signInDevice() {
            this.adding = true;
            auth.signInWithEmailAndPassword(this.email, this.password)
                .then((data) => {
                console.log(data);
                console.log('Successfully logged in device!');
                this.addDevice();
            })
                .catch(error => {
                this.adding = false;
                switch (error.code) {
                    case 'auth/invalid-email':
                        this.errMsg = 'Ongeldige E-mail.';
                        break;
                    case 'auth/user-not-found':
                        this.errMsg = 'Geen account gevonden met dit E-mail adres';
                        break;
                    case 'auth/wrong-password':
                        this.errMsg = 'Wachtwoord onjuist.';
                        break;
                    default:
                        this.errMsg = 'E-mail of wachtwoord is incorrect.';
                        break;
                }
            });
        },
        onGotUser(doc) {
            let schoolId = doc.data().schoolId;
            let addedTimeStamp = firestore.FieldValue.serverTimestamp();
            let deviceId = this.$route.params.deviceId;
            let newDeviceData = {
                deviceId: deviceId,
                added: addedTimeStamp,
                addedById: auth.currentUser.uid,
                schoolId: schoolId,
                sessionId: "",
                loggedIn: addedTimeStamp,
                name: "Quest 2",
                type: "Quest 2",
            };
            let batch = db.batch();
            batch.set(db.collection("/devices").doc(deviceId), newDeviceData);
            batch.set(db.collection("/schools").doc(schoolId).collection("devices").doc(deviceId), newDeviceData);
            batch.commit()
                .then(() => {
                console.log("Added device");
                router.push('/logindevice/added');
            }).catch(error => {
                this.adding = false;
                console.log(error);
                this.errMsg = "Apparaat kon niet gekoppeld worden.";
            });
        },
        addDevice() {
            console.log("Adding device for " + auth.currentUser.uid);
            db.collection("/users").doc(auth.currentUser.uid).get().then((doc) => this.onGotUser(doc)).catch(error => {
                this.adding = false;
                console.log(error);
                this.errMsg = "Deze gebruiker is nog niet gekoppeld aan een school. Neem contact op met Yuha.";
            });
        },
    },
    components: { LoadingSpinner }
}
</script>

<style>
.container{
  text-align: center;
}
.logo{
  padding:30px;
}
</style>