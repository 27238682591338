<template>
    <div class="tag-input mb-2" v-if="false">
      <input
        type="text"
        class="form-control"
        v-model="inputValue"
        @input="onInput"
        @keydown.enter.prevent="addTag"
        placeholder="Tags toevoegen"
      />
      <div class="mt-2" v-if="inputValue != ''">
        <span class="badge bg-success me-1"
            v-for="(tag, index) in filteredTags"
            :key="index"
            @click="selectTag(tag)"
            >
            {{ tag }} +
        </span>
      </div>
      <div class="mt-2">
        <span
          v-for="(tag, index) in selectedTags"
          :key="index"
          class="badge bg-primary me-1"
        >
          {{ tag }}
          <button
            type="button"
            class="btn-close btn-close-white ms-1"
            aria-label="Remove"
            @click="removeTag(index)"
          ></button>
        </span>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        inputValue: '',
        selectedTags: [],
        existingTags: ['Begripsvorming', 'Toetsing', 'Oefenen'],
      };
    },
    computed: {
      filteredTags() {
        return this.existingTags.filter((tag) =>
          tag.toLowerCase().includes(this.inputValue.toLowerCase())
        );
      },
    },
    methods: {
      onInput() {
        // Handle input event if necessary
      },
      addTag() {
        const tag = this.inputValue.trim();
        if (tag && !this.selectedTags.includes(tag)) {
          this.selectedTags.push(tag);
        }
        this.inputValue = '';
      },
      selectTag(tag) {
        if (!this.selectedTags.includes(tag)) {
          this.selectedTags.push(tag);
        }
        this.inputValue = '';
      },
      removeTag(index) {
        this.selectedTags.splice(index, 1);
      },
    },
  };
  </script>
  
  <style scoped>
  .tag-input {
    margin: 0 auto;
  }
  .badge {
    display: inline-flex;
    align-items: center;
  }
  .btn-close {
    font-size: 0.6rem;
  }
  </style>
  