<template>
    <div>
        <a class="mb-2 text-decoration-none" href="#" @click="addQuestionToArray(editingGoal.questions)">+ Voeg vraag toe</a>

        <div class="input-group smoothContainer" v-for="(question, questionIndex) in editingGoal.questions" :key="questionIndex">
            <div class="form-floating mt-2 mb-2"> 
                <textarea style="height: 100%;" rows="4" cols="50" v-model="question.question" id="goalInput" aria-label="question" class="form-control shared-textarea" placeholder="Bijvoorbeeld: 1/2,2/4,4/8"></textarea>
                <label for="goalInput">Vraag</label>
            </div>
            <div class="form-floating mt-2 mb-2"> 
                <textarea style="height: 100%;" rows="4" cols="50" v-model="question.rightOptionsText" id="rightOptions" aria-label="rightOptions" @input="question.rightOptionsText = forceToAnswersArray(question.rightOptionsText)" class="form-control shared-textarea" placeholder="Bijvoorbeeld: 1/2,2/4,4/8"></textarea>
                <label for="rightOptions">Juiste opties</label>
            </div>
            <div class="form-floating mt-2 mb-2"> 
                <textarea style="height: 100%;" rows="4" cols="50" v-model="question.wrongOptionsText" id="wrongOptions" aria-label="wrongOptions" @input="question.wrongOptionsText = forceToAnswersArray(question.wrongOptionsText)" class="form-control shared-textarea" placeholder="Bijvoorbeeld: 2/2,3/4,6/8"></textarea>
                <label for="wrongOptions">Onjuiste opties</label>
            </div>
            <div class="input-group mb-3 text-decoration-none">
                <a href="#" class="tinyRemove text-danger text-decoration-none" @click="removeQuestion(editingGoal.questions,questionIndex)">Vraag verwijderen <fa icon="fa-solid fa-remove" /></a> 
            </div>
        </div>
    </div>
</template>

<script>
export default {
  props: {
    editingGoal: Object,
    questionTemplate: Object,
    levelIndex: Number
  },
  methods: {
    addQuestionToArray(questions){
      console.log("Adding new question")
      const newQuestion = { ...this.questionTemplate };
      questions.push(
        newQuestion
      );
    },
    removeQuestionFromArray(questions, questionIndex){
        questions.splice(questionIndex, 1);
    }
  }
}
</script>