<template>
  <h1>Leerlingen</h1><br>

  <div class="nav justify-content-end mb-md-3" v-if="this.$store.state.user.admin">
    <button class="btn btn-primary m-2" type="button" @click="addNewGroup"><fa icon="fa-solid fa-users" /> Groep toevoegen</button>
  </div>
  <div v-if="!loadingGroups && studentGroups.length == 0" class="smoothContainer text-center">
   Er zijn nog geen groepen aangemaakt.<br>
   Gebruik de 'Groep toevoegen' knop om een eerste groep/klas aan te maken.  
  </div>

  <div class="smoothContainer" id="groupsAccordion" v-for="(group, groupIndex) in studentGroups" :key="groupIndex" >
      <h5 :class="{'mb-0':!group.opened}" @click="toggleStudentsPane($event,group)" role="button" :id="'header' + groupIndex" class="user-select-none w-100">
        {{ group.name }}
        <span class="float-end">
          <fa icon="fa fa-angle-up" v-if="group.opened" />
          <fa icon="fa fa-angle-down" v-else />
        </span>
      </h5>

      <div class="animateIn" :id="'collapse' + groupIndex" v-if="group.opened" >
        <div>
          <LoadingSpinner :loading="group.loading" />
          <div class="nav justify-content-end mb-md-2 animateIn" v-if="this.$store.state.user.admin">
            <button class="btn btn-primary btn-sm m-2" type="button" @click="renameGroup(group)"><fa icon="fa-solid fa-pen-to-square" /> Groep hernoemen</button>
            <button class="btn btn-primary btn-sm m-2" type="button" @click="addNewStudent"><fa icon="fa-solid fa-user" /> Leerling toevoegen</button>
            <button class="btn btn-danger btn-sm m-2" type="button" @click="removeGroup(group)"><fa icon="fa-solid fa-trash" /> Groep verwijderen</button>
          </div>
          
          <table class="table table-hover">
            <!--<thead v-if="true">
              <tr>
                <th scope="col"><fa icon="fa fa-user" /></th>
                <th scope="col"><fa icon="fa fa-bullseye" /></th>
                <th scope="col"><fa icon="fa fa-thumbs-up" /></th>
                <th scope="col"><fa icon="fa fa-person-running" /></th>
                <th scope="col"><fa icon="fa fa-star" /></th>
                <th scope="col"><fa icon="fa fa-comment-dots" /></th>
              </tr>
            </thead>-->
            <tbody>
              <tr @click="openStudent(student)" style="cursor:pointer !important;" v-for="(student, studentIndex) in group.students" :key=studentIndex>
                  <td>{{ student.name }}</td>
                  <!--<td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>-->
                </tr>
            </tbody>
          </table>
        </div>
      </div>
</div>

<HelpComponent message='<p>Met ons systeem kun je gemakkelijk groepen aanmaken en leerlingen toevoegen aan deze groepen.<br>
        Zo kun je bijvoorbeeld een groep maken voor alle leerlingen in een bepaalde klas, of een groep voor leerlingen die extra hulp nodig hebben.<br>
        Om een nieuwe groep aan te maken, klik je op de knop "Groep toevoegen" in het menu aan de rechterkant van het scherm.</p>
        <p>Om leerlingen aan een groep toe te voegen, klik je eerst op de groep waaraan je leerlingen wilt toevoegen. Vervolgens klik je op de knop "Leerling toevoegen" en voer je de naam van de leerling in.</p>
        <p>Als je de details van een leerling wilt bekijken, klik je eerst op de groep waarbinnen de leerling zit. Vervolgens klik je op de naam van de leerling in de lijst met leerlingen in de groep. Dit zal een nieuw scherm openen met alle details van de leerling, zoals hun naam, leeftijd en voortgang.</p>'/>


</template>

<script>
import { db } from '@/main'
import { firestore } from '@/main'
import { router } from '@/router';
import HelpComponent from './Nested/HelpComponent.vue'
import LoadingSpinner from './Nested/LoadingSpinner.vue';

export default {
  name: "StudentGroups",
  components: {
    HelpComponent,
    LoadingSpinner
},
  data() {
    return {
      loadingGroups: true,
      lastLoadedGroup: null,
      ungroupedStudents: [],
      modifiedStudentData: {},
      activeStudent: null,
      help: false,
      studentGroups: [],
    };
  },
  methods: {
    toggleStudentsPane(event,targetGroup){
      console.log(targetGroup.opened);
      if(targetGroup.opened == true){
        targetGroup.opened = false;
      }
      else{
        this.loadStudents(targetGroup);
      }

      if (!event.shiftKey) {
        //Close all other groups
        this.studentGroups.forEach((group) => {
          if(group.id != targetGroup.id){
            group.opened = false;
          }
        });
      } 
    },
    loadStudents(targetGroup)
    {
      targetGroup.opened = true;

      this.$store.state.groupId = targetGroup.id;  
      this.lastLoadedGroup = targetGroup;
      console.log("Load students for group " + targetGroup.id);
      
      db.collection("/schools/" + this.$store.state.school + "/students").where('groupId','==',targetGroup.id).get().then(this.assignStudentsToGroup);  
    },
    loadUngroupedStudents()
    {
      console.log("Load ungrouped students");
      db.collection("/schools/" + this.$store.state.school + "/students").where('groupId','==','').get().then((items) => {
        let _ungroupedStudents = [];
        items.forEach((item) => {
          let _student = item.data();
          _student.id = item.id;
          _ungroupedStudents.push(_student);
        });

        this.ungroupedStudents = _ungroupedStudents;
      })
    },
    assignStudentsToGroup(studentDocs){
      let _students = [];
      studentDocs.forEach((item) => {
        let student = item.data();
        student.id = item.id;

        //Load last 3 results for student
        db.collection("/activities").doc(student.activityId).collection("results").orderBy("logged", "desc").limit(3).get().then((activityDocs) => {
          activityDocs.forEach((activityDoc) => {
            let activity = activityDoc.data();
            student.activity = activity;
          });
        });

        _students.push(student);
      });

      this.lastLoadedGroup.students = _students;
    },
    addNewGroup()
    {
      let groupName = prompt("Wat is de groep naam?");
      let timestamp = firestore.FieldValue.serverTimestamp()
      if(groupName != null){
        let newGroup = {
          name: groupName,
          number: 0,
          created: timestamp
        }
        let newDoc = db.collection("/schools/" + this.$store.state.school + "/groups").doc();
        newDoc.set(newGroup);
        console.log("Added new group with auto ID" + newDoc.id); 
      } 
    },
    renameGroup(group){
      let groupName = prompt("Groep '" + group.name + "' hernoemen:");
      if(groupName != null){
        db.collection("/schools/" + this.$store.state.school + "/groups").doc(group.id).update({
          name:groupName
        }).then(() => {
            console.log("Group successfully renamed!");
        }).catch((error) => {
            console.error("Error renaming group document: ", error);
        });
      } 
    },
    removeGroup(group)
    {
      if(confirm("Remove group " + group.name + "?"))
      {
        console.log("Removing group");  
        db.collection("/schools/" + this.$store.state.school + "/groups").doc(group.id).delete().then(() => {
            console.log("Group successfully deleted!");
        }).catch((error) => {
            console.error("Error removing document: ", error);
        });
       }
    },
    addNewStudent()
    {
      let groupId = "";
      if(this.lastLoadedGroup != null)
      {
        groupId = this.lastLoadedGroup.id;
      }
      let timestamp = firestore.FieldValue.serverTimestamp();
      let newStudent = {
          name: "Nieuwe student",
          number: 0,
          groupId: groupId,
          created: timestamp
      }
      let newDoc = db.collection("/schools/" + this.$store.state.school + "/students").doc();
      newDoc.set(newStudent);
      console.log("Added new student with auto ID" + newDoc.id); 

      this.openStudent(newDoc);
    },
    openStudent(student)
    {
      router.push('/student/' + student.id);
    },
    onGroupDataChanged(items) {
      let _studentGroups = [];

      items.forEach((item) => {
        const group = item.data();
        group.id = item.id;
        group.opened = false;
        if(this.$store.state.groupId == group.id){
          console.log("Load students for stored group " + this.$store.state.groupId);
          group.opened = true;
          this.loadStudents(group);
        }
        else{
          group.opened = false;
        }

        _studentGroups.push(group);

        //Move group.opened to top of list
        if(group.opened){
          _studentGroups.unshift(_studentGroups.pop());
        }
      });

      this.studentGroups = _studentGroups;
      this.loadingGroups = false; 
    },
  },
  mounted() {
    this.unsubscribe = db.collection("/schools/" + this.$store.state.school + "/groups").orderBy("name", "desc").onSnapshot(this.onGroupDataChanged);
  },
  beforeUnmount() {
    this.unsubscribe();
  }
};
</script>

<style>
 .opened{
    -webkit-box-shadow: inset 3px 3px 6px -1px rgba(0,0,0,0.31);
    -moz-box-shadow: inset 3px 3px 6px -1px rgba(0,0,0,0.31);
    box-shadow: inset 3px 3px 6px -1px rgba(0,0,0,0.31);
    padding: 20px;
 }
.group{
  color: #30b2d9;
}
.modal-content{
  -webkit-box-shadow: 10px 10px 14px -11px rgba(0,0,0,0.34);
  -moz-box-shadow: 10px 10px 14px -11px rgba(0,0,0,0.34);
  box-shadow: 10px 10px 14px -11px rgba(0,0,0,0.34);
}
.student
{
    color: #3c4795;
    padding: 10px;
    font-weight: bold;
    border-radius: 4px;
    -webkit-box-shadow: 3px 3px 2px -1px rgba(0,0,0,0.31);
    -moz-box-shadow: 3px 3px 2px -1px rgba(0,0,0,0.31);
    box-shadow: 3px 3px 2px -1px rgba(0,0,0,0.31);
}
.student a:link{
  text-decoration:none;
}

</style>