<template>

  <a class="mb-2 text-decoration-none" href="#" @click="addWord()">+ Voeg woord toe</a>
  <!--Add checkbox to toggle auto image search google-->
  <div class="form-check mt-2 mb-2 form-switch">
    <input class="form-check-input" type="checkbox" id="autoImageSearchGoogle" v-model="autoImageSearch">
    <label class="form-check-label" for="autoImageSearchGoogle">Automatisch afbeeldingen zoeken (pixabay)</label>
  </div>

  <div class="smoothContainer" v-for="(text, textIndex) in texts" :key="textIndex">
    <div class="input-group">
      <div class="form-floating">
        <input type="text" 
          @keydown.enter.prevent="searchWordImage(textIndex)" 
          v-model="text.text" 
          :id="'wordInput' + textIndex" aria-label="question" class="form-control"
          placeholder="Woord">
        <label for="goalInput">Woord</label>
      </div>

      <div class="form-floating small-form-items">
        <input type="file" @change="onFileChange($event, textIndex)" id="imageInput" aria-label="image"
          class="form-control d-none" accept=".jpg, .jpeg, .png">
        <div class="image-placeholder" @click="triggerFileInput(textIndex)" :style="{ backgroundImage: text.imageFileUrl ? 'url(' + text.imageFileUrl + ')' : '' }">
          <fa v-if="!text.imageFileUrl" icon="fa-solid fa-image" class="placeholder-icon" />
        </div>
      </div>

      <div class="form-floating small-form-items" style="max-width: 20px;">
        <a href="#" class="tinyRemove text-danger text-decoration-none" @click="removeText(textIndex)">
          <fa icon="fa-solid fa-remove" />
        </a>
      </div>
    </div>
    <div class="input-group">
    <!--Show 5 suggestions as bootstrap 6 thumbnails-->
    <div class="input-group row mt-3" v-if="autoImageSearch">
      <LoadingSpinner :loading="text.searching" />

      <div class="col-12">
        <div class="row">
          <div class="col-4 mb-2" v-for="(suggestionUrl, suggestionIndex) in text.suggestions" :key="suggestionIndex">
            <div class="card" @click="downloadImageUrlAndApplyToWord(suggestionUrl,textIndex)">
              <img  :src="suggestionUrl" class="card-img" :alt="suggestionUrl" style="cursor: pointer;">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>   

  </div>

</template>

<script>
import { storage } from '@/main'; // Adjust the path as necessary
import LoadingSpinner from '../Nested/LoadingSpinner.vue';

export default {
name: 'SpeechLevelSettings',
components: {
  LoadingSpinner
},
data() {
  return {
    autoImageSearch: false,
    texts: []
  }
},
props: {
  editingGoal: Object,
  levelIndex: Number,
  gameIndex: Number
},
mounted() {
  //set texts to a copy of the editingGoal texts (if it has it)
  //force all searching to be false
  this.texts = this.editingGoal.texts ? JSON.parse(JSON.stringify(this.editingGoal.texts)) : [];
  this.texts.forEach(text => text.searching = false);
},
emits: ['propertyChanged', 'answersCountChanged'], 
watch: {
  texts(newValue, oldValue) {
    console.log("texts changed from " + oldValue + " to " + newValue);
    this.$emit('propertyChanged', this.editingGoal, "texts", newValue);
  },
},
methods: 
{
  searchWordImage(textIndex)
  {
    var text = this.texts[textIndex].text;
    console.log("Search word image");

    this.texts[textIndex].suggestions = [];

    if(text.length > 1 && this.autoImageSearch)
    {
      console.log("Auto image search google");
      this.suggestImages(textIndex);
    }
  },
  async suggestImages(textIndex)
  { 
      //Get the search term
      var searchTerm = this.texts[textIndex].text;
      this.texts[textIndex].searching = true;

      //Make the request
      //fetch(`https://europe-west1-yuha-70bb6.cloudfunctions.net/autoSearchImage?q=${searchTerm}`)
      var requestUrl = `https://europe-west1-yuha-70bb6.cloudfunctions.net/AutoImageSearch?q=${searchTerm}`;

      fetch(requestUrl)
      .then(response => response.json())
      .then(data => {
        console.log(data);
        //Fill suggestions array using this path: hits.webformatURL where hits is an array of image objects
        this.texts[textIndex].suggestions = data.hits.map(hit => hit.webformatURL);

        //Limit to 5 suggestions
        this.texts[textIndex].suggestions = this.texts[textIndex].suggestions.slice(0, 6);
        this.texts[textIndex].searching = false;
      })
      .catch((error) => {
        console.error('Error:', error);
        this.texts[textIndex].searching = false;
      });
  },
  addWord() {
    console.log("Adding new word");
    const newWord = {
      text: "",  
      imageFileUrl: null,
      suggestions: [],
      searching: false,
    };

    this.texts.unshift(newWord);
    this.$emit('answersCountChanged', this.gameIndex, this.levelIndex, this.texts.length);

    //autoselect the input item
    this.$nextTick(() => {
      const input = document.querySelector("#wordInput0");
      input.focus();
    });
  },
  removeText(textIndex) {
    this.removeImageFromStorage(textIndex);

    this.texts.splice(textIndex, 1);
    this.$emit('answersCountChanged', this.gameIndex, this.levelIndex, this.texts.length);
  },
  downloadImageUrlAndApplyToWord(imageUrl, textIndex)
  {
    //Remove previous image from storage (if it exists)
    this.removeImageFromStorage(textIndex);
    this.uploadImageFileFromUrl(imageUrl, textIndex);

    //Clear suggestions
    this.texts[textIndex].suggestions = [];
  },
  removeImageFromStorage(textIndex)
  {
    if(this.texts[textIndex].imageFileUrl && this.texts[textIndex].imageFileUrl.length > 0)
    {
      const storageRef = storage.refFromURL(this.texts[textIndex].imageFileUrl);
      storageRef.delete().then(() => {
        console.log("Image deleted from storage");
      }).catch((error) => {
        console.error("Error deleting image from storage: " + error);
      });
    }
  },
  async onFileChange(event, textIndex) {
    const file = event.target.files[0];
    if (file) {
      await this.uploadImageFile(file, textIndex);
    }
  },
  async uploadImageFile(file, textIndex) 
  {
    // Generate a GUID
    const guid = this.generateGUID();

    // Extract the file extension
    const fileExtension = file.name.split('.').pop();

    // Create a new filename
    const newFileName = `${guid}.${fileExtension}`;

    // Upload the file to Firebase Storage
    const storageRef = storage.ref();
    const fileRef = storageRef.child(`yuha/words/images/${newFileName}`);
    await fileRef.put(file);

    // Get the download URL
    const fileUrl = await fileRef.getDownloadURL();

    // Update the imageFileUrl in texts array
    this.texts[textIndex].imageFileUrl = fileUrl;

    // Emit the propertyChanged event
    this.$emit('propertyChanged', this.editingGoal, "texts", this.texts);
  },
  async uploadImageFileFromUrl(url, textIndex)
  {
    console.log("Downloading image from url: " + url);
    var xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.onload = async () => {
      const blob = xhr.response;
      const file = new File([blob], 'image.jpg', { type: blob.type });
      await this.uploadImageFile(file, textIndex);
    };
    xhr.open('GET', url);
    xhr.send();
  },
  generateGUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  },
  triggerFileInput(textIndex) {
    const fileInput = document.querySelectorAll("#imageInput")[textIndex];
    fileInput.click();
  }
}
}
</script>

<style>
.image-placeholder {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  border: 1px dashed #ccc;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.placeholder-icon {
  font-size: 2rem;
  color: #ccc;
}
.small-form-items{
  max-width: 100px;
}
</style>
