<template>
  <div>
    <h6>Expliciet</h6>
    <textarea v-model="explicit" aria-label="explicit" @input="forceToArrayStringMath()" class="form-control mb-2"
      placeholder="Bijvoorbeeld: 1x2,4:8,10x10"></textarea>
    <h6>Overige opties</h6>
    <div class="form-check">
      <input class="form-check-input" type="checkbox" :id="levelIndex + 'showHelpers'"
        v-model="this.helpers" checked>
      <label class="form-check-label" :for="levelIndex + 'showHelpers'">
        Toon antwoord
      </label>
    </div>
    <div class="form-check">
      <input class="form-check-input" type="checkbox" value="" :id="levelIndex + 'randomOrder' + this.editingGoal.id"
        v-model="this.randomize">
      <label class="form-check-label" :for="levelIndex + 'randomOrder' + this.editingGoal.id">
        Willekeurige volgorde
      </label>
    </div>
    <div class="form-check">
      <input class="form-check-input" type="checkbox" value="" :id="levelIndex + 'makeOwnQuestion' + this.editingGoal.id"
        v-model="this.free">
      <label class="form-check-label" :for="levelIndex + 'makeOwnQuestion' + this.editingGoal.id">
        Zelf vraag maken
      </label>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return {
        explicit: '',
        randomize: false,
        free: false,
        helpers: true
    }
  },
  props: {
    editingGoal: Object,
    questionTemplate: Object,
    levelIndex: Number
  },
  emits: ['propertyChanged'],
  mounted() {
    this.explicit = this.editingGoal.explicit;
    this.randomize = this.editingGoal.randomize;
    this.free = this.editingGoal.free;
    this.helpers = this.editingGoal.helpers;
  },
  watch: {
    explicit(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$emit('propertyChanged', this.editingGoal, "explicit", newValue);
      }
    },
    randomize(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$emit('propertyChanged', this.editingGoal,"randomize", newValue);
      }
    },
    free(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$emit('propertyChanged', this.editingGoal,"free",newValue);
      }
    },
    helpers(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$emit('propertyChanged', this.editingGoal,"helpers",newValue);
      }
    }
  },
  methods: {
    forceToArrayStringMath(){
      //Turn tabs and spaces into commas
      let cleaned = this.explicit.replace(/[\t]+/g, ",");

      //Allow multiply wildcard char
      cleaned= cleaned.replace(/[*]/g, "x");

      //Consistent divide character (forward and backward slash becomes : )
      cleaned= cleaned.replace(/[/\\]/g, ":");

      //Clean special characters except numbers, dots and commas and operators
      cleaned = cleaned.replace(/[^.,\dx+-:%?=\n]/g, "");
      this.explicit = cleaned;
    }, 
  }
}
</script>