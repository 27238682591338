import { db } from "@/main";

export const shared = {
  generateToken() {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let token = '';
    for (let i = 0; i < 10; i++) {
      token += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return token;
  },

  async stopActivitiesOnDevice(deviceId) {
    console.log("Stop activities on device");
    const querySnapshot = await db
      .collection("/activities")
      .where("deviceId", "==", deviceId)
      .where("state", "==", 1)
      .get();

    //Flag as done state 2
    const batch = db.batch();

    let amountOfRunningActivities = 0;
    querySnapshot.forEach((item) => {
      amountOfRunningActivities++;
      console.log(
        "Trying to stop " + amountOfRunningActivities + " activities on device"
      );

      let _activity = item.data();
      _activity.id = item.id;
      batch.update(
        db.collection("/activities").doc(_activity.id),
        {
          state: 2,
        },
        { merge: true }
      );
    });

    try {
      if (amountOfRunningActivities > 0) {
        await batch.commit();
        console.log(
          "Ended " + amountOfRunningActivities + " running activities on device"
        );
      }
    } catch (error) {
      console.error("Error committing batched writes: ", error);
    }
  },
  
  async attributeActivities(studentId, schoolId) {
    try {
      //First check all activities assigned to this student, and if they are attributed to this student
      const querySnapshot = await db
        .collection("/activities")
        .where("schoolId", "==", schoolId)
        .where("studentId", "==", studentId)
        .where("state", "==", 2)
        .where("attributed", "==", false)
        .get();

      //Create unattributed actitivies list
      let _unattributedActivites = [];
      querySnapshot.forEach((item) => {
        let _activity = item.data();
        _activity.id = item.id;
        _activity.subgoals = null;
        _activity.loading = false;
        _unattributedActivites.push(_activity);
      });

      if (_unattributedActivites.length > 0) {
        console.log(
          "Found unattributed activities for students: " +
            querySnapshot.docs.length
        );
        for (const activity of _unattributedActivites) {
          const items = await db
            .collection("/activities")
            .doc(activity.id)
            .collection("results")
            .where("levelComplete", "==", true)
            .orderBy("level", "desc")
            .get();

          //Save goals that are completed by these activities
          const batch = db.batch();
          items.forEach((item) => {
            const _result = item.data();
            _result.id = item.id;
            const goalIds = _result.goalIds;

            goalIds.forEach((goalId) => {
              //Set student goals to store goal id global progress
              batch.set(
                db
                  .collection("/schools")
                  .doc(schoolId)
                  .collection("students")
                  .doc(studentId)
                  .collection("goals")
                  .doc(goalId),
                {},
                { merge: true }
              );
            });
          });

          //Flag activity as attributed
          batch.update(db.collection("/activities").doc(activity.id), {
            attributed: true,
          });

          //Commit
          try {
            await batch.commit();
            console.log(
              "Batched writes student attributed goals successfully committed!"
            );
          } catch (error) {
            console.error("Error committing batched writes: ", error);
          }
        }
      } else {
        console.log("No unattributed activities");
      }
    } catch (error) {
      console.error("Error getting unattributed activities: ", error);
    }
  },
};
