<template>
    <a class="text-decoration-none" v-if="!help" @click="help=!help"><fa icon="fa-solid fa-circle-info" /> Uitleg</a>
    <a class="text-decoration-none" v-if="help" @click="help=!help">
        <div class="animateIn alert alert-primary" role="alert">
        <h4 class="alert-heading">
        <fa icon="fa-solid fa-circle-info" /> Uitleg
        </h4>
        <div v-if="help" v-html="message">
        </div>  
    </div>
    </a><br><br>
</template>

<script>
export default {
  props: ['message'],
  data() {
    return {
      help: false
    };
  },
}
</script>