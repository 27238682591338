<template>
  <div v-if="showChart" style="height: 300px">
    <bar-chart class="progressChart" :data="chartData" :options="chartOptions"></bar-chart>
  </div>
</template>

<script>
export default {
props: {
  activities: Array,
},
data() {
  return {
    showChart: false,
    chartData: {},
    chartOptions: {
      responsive: true,
      scales: {
        x: {
          stacked: true,
          grid:{
            display:false
          },
          ticks: {
            font: {
              size: 16,
              weight: 'bold'
            },
          },
        },
        y: {
          stacked: false, //Make stacked bars overlap
          grid:{
            display:false
          },
          ticks: {
            stepSize: 1,
            font: {
              size: 16,
              weight: 'bold'
            },
          }
        }
      },
      maintainAspectRatio: false,
      tension: 0.5,
      plugins: {
        datalabels: {
          formatter: function(value, context) {
              let minutes = value;
              let seconds = context.dataset.extraData[context.dataIndex];
              
              return  context.datasetIndex == 0 ? `${minutes}.${seconds}` : '';
          },
          font: {
            size: 16,
            weight: 'bold'
          },
          align: 'end',
          anchor: 'end'
        },
        legend: {
          reverse:true,
          display:true,
          position: 'bottom',
          labels: {
            boxWidth: 15,
            font: {
              size: 16,
              weight: 'bold'
            }
          }
        }
      }
    }
  };
},
mounted() {
  this.renderBarChart();
},
methods: {
  formatActivity(activity) {
    let date = activity.started.toDate();
    let today = new Date();
    let isToday = date.getDate() == today.getDate() &&
    date.getMonth() == today.getMonth() &&
    date.getFullYear() == today.getFullYear();

    let time = date.toLocaleTimeString('nl-NL', { hour: '2-digit', minute: '2-digit' });

    if (isToday) {
      return "Vandaag (" + time + ")";
    }
    let formatted = date.toLocaleDateString('nl-NL', { day: '2-digit', month: 'numeric' });
    
    return formatted;
  },
  renderBarChart() {
    //Get data from last activities
    let days = [];
    let actualPlaytimes = [];
    let actualPlaytimesRemainingSeconds = [];
    let playTimes = [];
    let playtimesRemainingSeconds = [];

    for (let i = 0; i < this.activities.length; i++) {
      const activity = this.activities[i];
      days.push(this.formatActivity(activity));
      
      let actualPlaytimeMinutesValue = Math.floor((activity.actualPlaytime || 0) / 60); //Seconds to minutes (floor)
      let actualPlaytimeSecondsValue =  (activity.actualPlaytime || 0) - (actualPlaytimeMinutesValue * 60); //Extra seconds to show in labels

      let playtimeMinutesValue = Math.round((activity.playtime) / 60); //Seconds to minutes 
      let playtimeSecondsValue =  (activity.playtime) - (playtimeMinutesValue * 60); //Extra seconds to show in labels

      actualPlaytimes.push(actualPlaytimeMinutesValue);
      actualPlaytimesRemainingSeconds.push(actualPlaytimeSecondsValue);

      playTimes.push(playtimeMinutesValue);
      playtimesRemainingSeconds.push(playtimeSecondsValue);
    }

    days.reverse();
    actualPlaytimes.reverse();
    actualPlaytimesRemainingSeconds.reverse();
    playtimesRemainingSeconds.reverse();
    playTimes.reverse();

    this.chartData = {
      labels: days,
      datasets: [
        {
          label: 'Actieve speeltijd (minuten)',
          barPercentage: 0.4,
          borderSkipped: false,
          backgroundColor: '#e22b77',
          borderColor: '#e22b77',
          data: actualPlaytimes,
          extraData: actualPlaytimesRemainingSeconds
        },
        {
          label: 'Sessie speeltijd',
          borderRadius: 5,
          barPercentage: 0.4,
          borderSkipped: false,
          backgroundColor: '#e2f9ff',
          borderColor: '#e2f9ff',
          data: playTimes,
          extraData: playtimesRemainingSeconds
        },
      ]
    };
    this.showChart = true;
  }
}
}
</script>

<style>
</style>