<template>
    <div v-if="showChart" style="height: 300px">
      <bar-chart class="progressChart" :data="chartData" :options="chartOptions"></bar-chart>
    </div>
</template>

<script>
export default {
  props: {
    activities: Array,
  },
  data() {
    return {
      showChart: false,
      chartData: {},
      chartOptions: {
        responsive: true,
        layout: {
          padding: {
            left: 10,
            right: 10,
            top: 30,
            bottom: 10
          }
        },
        scales: {
          x: {
            grid:{
              display:false
            },
            ticks: {
              font: {
                size: 16,
                weight: 'bold'
              },
            },
          },
          y: {
            grid:{
              display:false
            },
            ticks: {
              font: {
                size: 16,
                weight: 'bold'
              },
            }          }
        },
        maintainAspectRatio: false,
        tension: 0.5,
        plugins: {
          datalabels: {
            font: {
              size: 16,
              weight: 'bold'
            },
            align: 'end',
            anchor: 'end'
          },
          legend: {
            display:false,
            position: 'bottom',
            labels: {
              boxWidth: 15,
              font: {
                size: 16,
                weight: 'bold'
              }
            }
          }
        }
      }
    };
  },
  mounted() {
    this.renderBarChart();
  },
  methods: {
    formatActivity(activity) {
      let date = activity.started.toDate();
      let today = new Date();
      let isToday = date.getDate() == today.getDate() &&
        date.getMonth() == today.getMonth() &&
        date.getFullYear() == today.getFullYear();

      let time = date.toLocaleTimeString('nl-NL', { hour: '2-digit', minute: '2-digit' });

      if (isToday) {
        return "Vandaag (" + time + ")";
      }
      let formatted = date.toLocaleDateString('nl-NL', { day: '2-digit', month: 'numeric' });
      
      return formatted;
    },
    renderBarChart() {
      //Get data from last activities
      let days = [];
      let movement = [];

      for (let i = 0; i < this.activities.length; i++) {
        const activity = this.activities[i];
        days.push(this.formatActivity(activity));

        let movementValue = Math.round(activity.movement || 1);       
        movement.push(movementValue);
      }

      days.reverse();
      movement.reverse();

      this.chartData = {
        labels: days,
        datasets: [
          {
            label: 'Beweging',
            borderRadius: 10,
            barPercentage: 0.4,
            borderSkipped: false,
            backgroundColor: 'blueviolet',
            data: movement,
            borderColor: 'blueviolet',
          },
        ]
      };
      this.showChart = true;
    }
  }
}
</script>

<style>
</style>