<template>
  <h1>Leerkrachten</h1>
  
  <h5>Favoriete groep leerkracht</h5><br>
    <ul class="list-group">
      <li v-for="user in users" :key="user.id" class="list-group-item">
      <div class="row">
        <div class="col-6">
          <span><strong>{{ user.name }}</strong></span><br>
          <span><em><small>{{ user.lastLogin }}</small></em></span>
        </div>
        <div class="col-6">
          <form>
            <div class="form-group">
              <select v-model="user.groupId" @change="changeUserForSchool(user,user.groupId)" class="form-select">
                <option value="">Geen favoriete groep</option>
                <option v-for="group in groups" :key="group.id" :value="group.id">{{ group.name }}</option>
              </select>
            </div>
          </form>
        </div>
      </div>
      </li>
    </ul>

</template>

<script>
import { db } from '@/main'
import defaultAvatar from "@/assets/images/avatar.png";

export default {
  name: "DashboardModule",
  data() {
    return {
      users:[],
      groups: [] // Add groups array
    };
  },
  methods: {
    loadUsersForSchool() {
      db.collection("/users").where("schoolId", "==", this.$store.state.school).get().then((items) => {
        let _users = [];
        items.forEach((item) => {
          let _user = item.data();
          _user.id = item.id;

          //Favorite group
          _user.groupId = "";
          if(_user.groupIds && _user.groupIds.length > 0){
            _user.groupId = _user.groupIds[0];
          }

          //Last login date
          let dateJs = new Date();
          if (_user.lastLogin) {
            dateJs = _user.lastLogin.toDate();
          }
          let dateString = dateJs.toLocaleString();
          _user.lastLogin = dateString;

          //Avatar? Else use default
          if (!_user.avatar) {
            _user.avatar = defaultAvatar;
          }
          _users.push(_user);
        });

        this.users = _users;
      }
      );
    },
    loadGroups() {
      db.collection("/schools").doc(this.$store.state.school).collection("/groups").get().then((items) => {
        let _groups = [];
        items.forEach((item) => {
          let _group = item.data();
          _group.id = item.id;
          _groups.push(_group);
        });

        this.groups = _groups;
      });
    },
    changeUserForSchool(user, groupId){
      //Update user in collection users so the groupIds array is updated to [groupId]
      //Later we may support multiple groups, thats why we use an array
      db.collection("/users").doc(user.id).update({
        groupIds: [groupId]
      }).then(() => {
        console.log(user.id + " user updated");
      });

      //If user.id is same as this.$store.state.user.id, update the store.state.groupId
      if(user.id == this.$store.state.user.id){
        this.$store.state.groupId = groupId;
      }
    }
  },
  mounted() {
    this.loadUsersForSchool();
    this.loadGroups(); // Call loadGroups method
  }
};
</script>

<style scoped>
.coinAvatar{
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}
</style>
  